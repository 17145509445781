<!-- Settings -->
<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Wizard Body-->
                <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                    <div class="col-xl-12 col-xxl-7">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                <h3 class="mb-10 font-weight-bold text-dark"><i class="flaticon2-gear settingicon"></i> {{title_text}}</h3>

                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="form-group">
                                            <label>Module</label>
                                            <input type="text" class="form-control form-control-solid form-control-lg" v-model="form.controller_name">
                                            <span class="form-err" v-if="form.errors.has('controller_name')" >
                                              {{form.errors.get('controller_name')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--begin: Settings Actions -->
                            <div class="d-flex justify-content-between border-top pt-10">
                                <a @click="createModule" class="text-white">
                                    <div class="btn btn-primary font-weight-bold text-uppercase px-9 py-4">
                                        {{submit_btn_text}}
                                    </div>
                                </a>
                            </div>
                            <!--end: Settings Actions -->
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>


<style>
.settingicon{font-size:30px;}
.preview-container img{max-width:200px;}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from '@/core/services/api.form.services'
import {getModuleDetails} from "@/api/access";

export default {
  name: 'add_edit_module',
  data() {
    return {
      loading:false,
      error:null,
      form : new Form({
        controller_name : null,
      }),
      submit_btn_text: 'Add Module',
      title_text: 'Add Module',
      api_url: '/module/create',
      response_msg: 'Module created successfully.'
    }
  },
  components: {},
   mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Modules", route: "/module/list" },
      { title: "Add/Edit Module" }
    ]);
  },
    methods:{
      createModule: function() {
        this.loading=true;
        this.form.post(this.api_url).then((response) => {
          if(response.status){
              this.$router.push({ path: '/module/list' })
              this.loading=false;
              this.$notify({
                group: 'tevola-time-clock',
                type: 'success',
                text: this.response_msg,
                speed: 1000
              });
          }
        })
      },
    },
    created(){
    let moduleId = this.$route.params.moduleId;

    if(parseInt(moduleId) > 0){
      
      this.submit_btn_text = 'Update Module'
      this.title_text = 'Update Module'
      this.response_msg = 'Module updated successfully.'

     getModuleDetails(moduleId).then(response => {
        if(response.data.data.id){
          this.api_url = 'module/update/' + response.data.data.id
        }
        this.form = new Form(response.data.data)
      });
    }
  }
}
</script>
